import React, { ReactNode } from 'react'
import { THEME } from 'src/config/config'

interface Props {
  theme: string
  children: ReactNode
}

export const ThemeOnly: React.FC<Props> = ({ theme, children }) => {
  return <>{theme === THEME ? children : null}</>
}
