import { ISelectResource } from '../types/resource'

const SelectResources: ISelectResource[] = []

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resourceReducer = (state = SelectResources, action: any) => {
  switch (action.type) {
    case 'ADD_ITEM':
      return [...state, action.payload]
    case 'DEL_ITEM':
      return state.filter((item: ISelectResource) => item.id !== action.payload)
    case 'CLEAR_ALL':
      return []
    default:
      return state
  }
}

export default resourceReducer
