import {
  AMPLIFY_USER_POOL_API_CLIENT_ID,
  AMPLIFY_USER_POOL_API_CLIENT_SECRET,
  COGNITO_OAUTH_DOMAIN,
} from 'src/config/config'

export const getUserInfo = async () => {
  const response = await fetch(`https://${COGNITO_OAUTH_DOMAIN}/oauth2/userInfo`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  })

  return await response.json()
}

export const refreshTokenApi = async () => {
  const response = await fetch(`https://${COGNITO_OAUTH_DOMAIN}/oauth2/token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${Buffer.from(
        `${AMPLIFY_USER_POOL_API_CLIENT_ID}:${AMPLIFY_USER_POOL_API_CLIENT_SECRET}`,
      ).toString('base64')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refreshToken') || '',
    }),
  })

  return await response.json()
}
