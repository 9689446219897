export const PUSH_ORIGIN_URL = 'push_origin_url'

export const POP_ORIGIN_URL = 'pop_origin_url'

export interface OriginItem {
  originPath: string
  topicName?: string
  subTopicName?: string
  solutionName?: string
  companyName?: string
}

export interface OriginInfo {
  lastOrigin: OriginItem | null
  originList: Array<OriginItem>
}
