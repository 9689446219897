import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, legacy_createStore, Middleware, StoreEnhancer } from 'redux'

import reducer from './reducers'

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export const initStore = () => {
  return legacy_createStore(reducer, bindMiddleware([]))
}

export const wrapper = createWrapper(initStore)
