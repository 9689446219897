import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import { GRAPHQL_HOST, THEME } from 'src/config/config'

import { useAuthenticated } from './useAuthenticated'

const isSSOUser = () => {
  const { session } = useAuthenticated()
  return session?.user?.isSSOUser
}

const showSessionExpiredToast = () => {
  const toastId = 'session-expired-toast'
  if (!toast.isActive(toastId)) {
    toast.warning('Your session expired. Please sign in again.', { toastId })
  }
}

let apolloClientInstance: ApolloClient<NormalizedCacheObject>

const errorLink = onError((params) => {
  const { graphQLErrors, networkError } = params
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    if (!isSSOUser()) {
      Auth.signOut().then(() => {
        location.href = '/signin'
      })
    } else {
      localStorage.clear()
    }
    showSessionExpiredToast()
    return
  }
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED':
          if (!isSSOUser()) {
            Auth.signOut().then(() => {
              location.href = '/signin'
            })
          } else {
            localStorage.clear()
          }
          showSessionExpiredToast()
          return
      }
    }
  }
})

const httpLink = new HttpLink({
  uri: GRAPHQL_HOST,
  headers: {
    'x-organization-id': THEME === 'ilta' ? 'ilta' : 'lth',
  },
})

export const setAuthToLink = (token: string) => {
  // apolloClientInstance.setLink(
  //   from([
  //     errorLink,
  //     new HttpLink({
  //       uri: GRAPHQL_HOST,
  //       headers: { 'x-organization-id': THEME === 'ilta' ? 'ilta' : 'lth', Authorization: `Bearer ${token}` },
  //     }),
  //   ]),
  // )

  apolloClientInstance = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        ContentEntity: {
          keyFields: (object, context) => {
            if (context.fragmentMap?.vendorAnalysisForSolution) {
              return ['attributes', ['slug']]
            }
            return ['id']
          },
        },
      },
    }),
    link: from([
      errorLink,
      new HttpLink({
        uri: GRAPHQL_HOST,
        headers: { 'x-organization-id': THEME === 'ilta' ? 'ilta' : 'lth', Authorization: `Bearer ${token}` },
      }),
    ]),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })
}

export const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (!apolloClientInstance) {
    apolloClientInstance = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          ContentEntity: {
            keyFields: (object, context) => {
              if (context.fragmentMap?.vendorAnalysisForSolution) {
                return ['attributes', ['slug']]
              }
              return ['id']
            },
          },
        },
      }),
      link: from([errorLink, httpLink]),
      connectToDevTools: process.env.NODE_ENV === 'development',
    })
  }

  return apolloClientInstance
}
