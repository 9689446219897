import { OriginInfo, POP_ORIGIN_URL, PUSH_ORIGIN_URL } from '../types/origin'

const originState: OriginInfo = {
  lastOrigin: null,
  originList: [],
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const originReducer = (state = originState, action: any) => {
  const newState: OriginInfo = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case PUSH_ORIGIN_URL:
      const originItem = action.originItem
      newState.lastOrigin = originItem
      newState.originList = [...state.originList, originItem]
      return newState
    case POP_ORIGIN_URL:
      newState.originList.splice(state.originList.length - 1, 1)
      newState.lastOrigin = newState.originList[newState.originList.length - 1]
      return newState
    default:
      return state
  }
}

export default originReducer
