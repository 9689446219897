import { CompareSolution } from 'src/types'

export const UPDATE_COMPARE_BY_SLUG = 'UPDATE_COMPARE_BY_SLUG'
export const REMOVE_ALL_COMPARE = 'REMOVE_ALL_COMPARE'
export const SAVE_SEARCH_PAGE_PATH = 'SAVE_SEARCH_PAGE_PATH'

export interface CompareInfos {
  solutions: CompareSolution[]
  path: string
}
