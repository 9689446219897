import { combineReducers } from 'redux'

import authInfo from './auth'
import compareInfos from './compare'
import originInfo from './origin'
import resource from './resource'
import toastInfo from './toast'

const reducer = combineReducers({
  compareInfos,
  toastInfo,
  originInfo,
  authInfo,
  resource,
})

export default reducer
