import { AuthInfo } from '../types/auth'

const initialState: AuthInfo = {
  name: '',
  email: '',
  username: '',
  idToken: '',
  id: '',
  companyName: '',
  isPremium: false,
  isVendor: false,
  isSSOUser: false,
  vendor: '',
  publishedAt: '',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        username: action.payload.username,
        idToken: action.payload.idToken,
        isSSOUser: action.payload.isSSOUser,
        vendor: action.payload.vendor,
        publishedAt: action.payload.publishedAt,
      }
    case 'SET_PROFILE':
      return {
        ...state,
        name: action.payload.name,
        id: action.payload.id,
        companyName: action.payload.companyName,
        isPremium: action.payload.isPremium,
        isVendor: action.payload.isVendor,
        vendor: action.payload.vendor,
        publishedAt: action.payload.publishedAt,
      }
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload.email,
      }
    case 'SET_VENDOR':
      return {
        ...state,
        vendor: action.payload.vendor,
      }
    case 'SET_PUBLISHEDAT':
      return {
        ...state,
        publishedAt: action.payload.publishedAt,
      }
    case 'CLEAR_AUTH':
      return {
        ...state,
        name: '',
        email: '',
        idToken: '',
        id: '',
        companyName: '',
        isPremium: false,
        isVendor: false,
        isSSOUser: false,
        vendor: '',
        publishedAt: '',
      }
    default:
      return state
  }
}

export default authReducer
