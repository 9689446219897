import { useSelector } from 'react-redux'
import { AppState } from 'src/store/types'
import { SessionType } from 'src/types'

interface AuthenticatedData {
  session: SessionType | null
  status: 'authenticated' | 'loading' | 'unauthenticated'
  isPremium: boolean
  isVendor: boolean
  isSSOUser: boolean
  publishedAt: string | null
  vendor: string | null
}

export const useAuthenticated = (): AuthenticatedData => {
  const authInfo = useSelector((state: AppState) => state.authInfo)

  const status = authInfo.idToken !== '' ? 'authenticated' : 'unauthenticated'
  const data = {
    user: {
      name: authInfo.name,
      email: authInfo.email,
      idToken: authInfo.idToken,
      id: authInfo.id,
      companyName: authInfo.companyName,
      isPremium: authInfo.isPremium,
      isVendor: authInfo.isVendor,
      confirmed: true,
      isSSOUser: authInfo.isSSOUser,
      vendor: authInfo.vendor,
      publishedAt: authInfo.publishedAt || null,
    },
  }
  if (!data || status !== 'authenticated') {
    return {
      session: null,
      status,
      isPremium: false,
      isVendor: false,
      isSSOUser: false,
      vendor: null,
      publishedAt: null,
    }
  }
  const session = data as unknown as SessionType
  return {
    session,
    status,
    isPremium: session.user.isPremium,
    isVendor: session.user.isVendor,
    isSSOUser: session.user.isSSOUser,
    publishedAt: session.user.publishedAt,
    vendor: session.user.vendor,
  }
}
