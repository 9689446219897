import { AuthInfo } from '../types/auth'

export const setAuth = (authInfo: AuthInfo) => ({
  type: 'SET_AUTH',
  payload: authInfo,
})

export const setEmail = (authInfo: AuthInfo) => ({
  type: 'SET_EMAIL',
  payload: authInfo,
})

export const setVendor = (vendor: string) => ({
  type: 'SET_VENDOR',
  payload: { vendor },
})

export const setProfile = (authInfo: AuthInfo) => ({
  type: 'SET_PROFILE',
  payload: authInfo,
})

export const removeAuth = () => ({
  type: 'CLEAR_AUTH',
  payload: {} as AuthInfo,
})
